<template>
	<div class="pageContainer">
		<a-row class="orderDetail">
			<a-row class="titleRow">
				<span class="longString"></span>
				订单信息
			</a-row>
			<a-row class="row">
				<a-col :span="6">订单编号：{{ data.flowId }}</a-col>
				<a-col :span="6">检测方式：安全检测</a-col>
				<a-col :span="6">联系方式： {{ securityCheckVoList.contactsName }}-{{ securityCheckVoList.contactsPhone }}</a-col>
				<a-col :span="6">材料：{{ securityCheckVoList.materials }}</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="6">订单创建时间：{{ this.$moment(data.addTime).format('YYYY-MM-DD HH:mm:ss') }}</a-col>
				<a-col :span="6">尺寸：宽{{ securityCheckVoList.width || '--' }}米 高{{ securityCheckVoList.extent || '--' }}米 总面积{{ securityCheckVoList.area || '--' }}平米</a-col>
				<a-col :span="6">详细地址：{{ securityCheckVoList.detailedAddress }}</a-col>
			</a-row>
			<a-divider class="longsString" />

			<a-table class="table" :columns="columns" :loading="tableLoading" :data-source="dataList" bordered :pagination="false" rowKey="data.flowId">
				<span slot="photoUrl">
					<img :src="securityCheckVoList.photoFrontUrl" class="img" @click="preview(securityCheckVoList.photoFrontUrl)" />
					<img :src="securityCheckVoList.photoDegreesUrl" class="img" @click="preview(securityCheckVoList.photoDegreesUrl)" />
				</span>
				<span slot="person">
					{{ data.checkUserName }}
				</span>
				<span slot="appointmentTime">
					{{ securityCheckVoList.appointmentTime ? $moment(securityCheckVoList.appointmentTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
				</span>
				<span slot="state">
					{{
						data.state == 0
							? '已取消'
							: data.state == 1
							? '预约中'
							: data.state == 2
							? '检测中'
							: data.state == 3
							? '待上传检测报告'
							: data.state == 4
							? '待确认'
							: data.state == 9
							? '待检测'
							: data.state == 8
							? '待设置'
							: data.state == 5
							? '已完成'
							: '--'
					}}
				</span>
				<span slot="operation" class="table-operation">
					<a v-if="data.checkReportUrl && data.balancePayState == 1 && (data.state == 4 || data.state == 5)" :href="data.checkReportUrl" target="_blank">下载检测报告</a>
					<a v-else>待上传检测报告</a>
				</span>

				<template slot="footer">
					<div class="tableFooter">
						<a-row>
							<a-col :span="3">
								检测总费用：
								<span class="red">￥{{ ((data.advanceAmount + data.balanceAmount) / 1000).toFixed(2) || 0 }}</span>
							</a-col>
							<a-col :span="3">
								预付款：
								<span class="orange">{{ data.advanceAmount ? '￥' + (data.advanceAmount / 1000).toFixed(2) : '￥0' }}</span>
							</a-col>
							<a-col :span="3">
								尾款：
								<span class="orange">{{ data.balanceAmount ? '￥' + (data.balanceAmount / 1000).toFixed(2) : '￥0' }}</span>
							</a-col>
						</a-row>
					</div>
				</template>
			</a-table>

			<a-row class="footerRow">
				<span class="button">
					<a-button
						type="primary"
						class="btn"
						@click="
							() => {
								this.$router.go(-1);
							}
						"
					>
						返回
					</a-button>
				</span>
			</a-row>
		</a-row>
		<Vviewer ref="viewer"></Vviewer>
	</div>
</template>

<script>
import Vviewer from '../../components/Vviewer';
import moment from 'moment';
export default {
	components: {
		Vviewer,
	},
	data() {
		const _this = this;
		return {
			flowId: '',
			host: '',
			data: {},
			securityCheckVoList: {},
			dataList: [{}],
			columns: [
				{
					title: '检测内容',
					dataIndex: 'photoUrl',
					key: 'photoUrl',
					align: 'center',

					scopedSlots: { customRender: 'photoUrl' },
				},
				{
					title: '检测类型',
					dataIndex: 'checkType',
					key: 'checkType',
					align: 'center',
					customRender(text, trecord) {
						return _this.securityCheckVoList.type == 0 ? '门头广告牌' : _this.securityCheckVoList.type == 1 ? '户外广告牌' : '--';
					},
				},
				{
					title: '预约检测时间',
					dataIndex: 'appointmentTime',
					key: 'appointmentTime',
					align: 'center',
					scopedSlots: { customRender: 'appointmentTime' },
				},
				{
					title: '检测机构',
					dataIndex: 'person',
					key: 'person',
					align: 'center',
					scopedSlots: { customRender: 'person' },
				},
				{
					title: '检测状态',
					dataIndex: 'state',
					key: 'state',
					align: 'center',
					scopedSlots: { customRender: 'state' },
				},
				{ title: '操作', key: 'operation', align: 'center', scopedSlots: { customRender: 'operation' } },
			],
			tableHeight: 450,
			tableLoading: true,
		};
	},
	created() {
		this.host = process.env.VUE_APP_RESOURCES_HOST;
		let flowId = this.$route && this.$route.query.tradeId;
		this.tableLoading = true;
		this.$http
			.get(`/securitycheckorder/query?flowId=${flowId}`)
			.then(res => {
				if (res.rescode == 200) {
					this.tableLoading = false;
					this.data = res.data;
					this.securityCheckVoList = res.data.securityCheckVo;
				} else {
					this.$message.warning(res.msg);
				}
			})
			.catch(error => {
				console.log(error);
			});

		if (document.body.clientWidth <= 1366) {
			this.tableHeight = 230;
		} else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
			this.tableHeight = 330;
		} else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
			this.tableHeight = 300;
		} else if (document.body.clientWidth > 1600) {
			this.tableHeight = 400;
		}
	},
	methods: {
		moment(data) {
			return this.$moment(data).format('YYYY-MM-DD HH:mm:ss');
		},

		preview(data) {
			this.$refs.viewer.show([
				{
					thumbnail: data,
					source: data,
				},
			]);
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	font-size: 16px;
	.orderDetail {
		height: 100%;
		background: #fff;
	}
	.titleRow {
		margin-top: 20px;
		font-size: 20px;
		position: relative;
		margin-left: 20px;
		.longString {
			width: 3px;
			height: 25px;
			background-color: #2d7bf8;
			position: absolute;
			top: -3px;
			left: -10px;
			line-height: 25px;
		}
	}
	.footerRow {
		margin-top: 20px;
		.button {
			float: right;
			.btn {
				margin-right: 20px;
			}
		}
	}
	h3 {
		font-weight: 800;
	}
	.row {
		margin-top: 10px;
		padding: 5px 30px;
	}
	.longsString {
		margin: 20px 0;
	}

	.table {
		.img {
			width: 70px;
			height: 40px;
			line-height: 40px;
			cursor: pointer; //鼠标变小手
			margin-right: 11px;
		}
		.icon {
			font-size: 25px;
			cursor: pointer; //鼠标变小手
		}
		.textLook {
			cursor: pointer; //鼠标变小手
		}
		.video {
			position: relative;
			.img {
				width: 70px;
				height: 40px;
			}
			.icon {
				font-size: 25px;
				cursor: pointer; //鼠标变小手
				position: absolute;
				top: -16px;
				left: 22px;
				color: #fff;
			}
		}
	}
	::v-deep .ant-table-thead > tr > th {
		background: rgba(237, 243, 255, 1) !important;
		border-bottom: none;
		border-top: none;
		text-align: center !important;
	}
	::v-deep .ant-table-footer {
		background: rgb(255, 255, 255) !important;
	}
	.orange {
		color: #ff9e02;
	}
	.blue {
		color: #40a9ff;
	}
	.red {
		color: red;
	}
}
</style>
