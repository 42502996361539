<template>
    <a-modal :visible="textVisible" :footer="false" class="mediaViewer" :width="600" @cancel="closeMediaPreviewModal">
        <a-icon type="close-circle" theme="twoTone" slot="closeIcon"></a-icon>
        <div class="text">
            <p class="textP">{{ contentText }}</p>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: ["textVisible", "closeTextModal", "contentText"],
    methods: {
        closeMediaPreviewModal() {
            this.closeTextModal();
        },
    },
};
</script>

<style lang="less" scoped>
.mediaViewer {
    /deep/.ant-modal {
        .ant-modal-content {
            .ant-modal-body {
                font-size: 0;
                padding: 0;
                .text {
                    width: 100%;
                    min-height: 200px;
                    .textP {
                        padding: 40px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>
