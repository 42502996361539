<template>
	<div class="pageContainer">
		<a-row class="orderDetail">
			<a-row class="titleRow">
				<span class="longString"></span>
				订单信息
			</a-row>
			<a-row class="row">
				<a-col :span="6">订单编号：{{ data.tradeId }}</a-col>
				<a-col :span="6">订单来源：{{ data.orderSource == 1 ? 'PC' : 'Mini Programs' }}</a-col>
				<a-col :span="6">检测行业：{{ industry || '--' }}</a-col>
				<a-col :span="6">检测方式：{{ data.type == 1 ? '智能检测' : data.type == 2 ? '人工审读' : data.type == 3 ? '专家审读' : '--' }}</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="6">订单创建时间：{{ this.$moment(data.addTime).format('YYYY-MM-DD HH:mm:ss') }}</a-col>
				<a-col :span="6">
					<span>支付时间：{{ data.payTime && data.payTime == '暂未支付' ? '暂未支付' : data.payTime ? this.$moment(data.payTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}</span>
				</a-col>
				<a-col :span="6">付款方式：{{ data.payType == 1 ? '支付宝支付' : data.payType == 2 ? '微信支付' : data.payType == 3 ? '会员免费' : '--' }}</a-col>
				<a-col :span="6">买家：{{ data.buyUserCode ? data.buyUserCode : '--' }}</a-col>
			</a-row>
			<a-row class="row">
				<a-col :span="6">
					订单支付状态：
					<a-tag
						:color="
							data.payState == 0 ? '#F89900' : data.payState == 1 ? '#52c41a' : data.payState == 2 ? '#f5222d' : data.payState == 3 ? '#13c2c2' : data.payState == 4 ? ' #722ed1' : ''
						"
					>
						{{ data.payState == 0 ? '待支付' : data.payState == 1 ? '已支付' : data.payState == 2 ? '支付失败' : data.payState == 3 ? '已退款' : data.payState == 4 ? '部分退款' : '--' }}
					</a-tag>
				</a-col>
			</a-row>
			<a-divider class="longsString" />
			<a-table class="table" :columns="columns" :data-source="dataList" rowKey="orderId" :scroll="{ y: tableHeight }">
				<span slot="content" slot-scope="text, record" @click="preview(record)">
					<img v-if="record.contentType == 1" :src="text" class="img" />
					<span class="video" v-else-if="record.contentType == 2 || record.contentType == 3">
						<img v-if="record.contentType == 2" :src="coverImgUrl(record.coverImgUrl)" class="img" />
						<a-icon type="play-circle" class="icon" />
						<img v-if="record.contentType == 3" src="https://resources.xbov.cn/img/hxznggfwpt/audioCodeImg.jpg" class="img" />
					</span>
					<span v-else-if="record.contentType == 4">{{ text && text.length > 8 ? text.substring(0, 8) + '...' : text ? text : '--' }}</span>
				</span>
				<span slot="operation" slot-scope="text, record" class="table-operation">
					<template v-if="data.state == 1">
						<span v-if="record.isCompliance == 1">合法</span>
						<a-tooltip placement="top" v-else-if="!record.monitorContentVoList || (record.monitorContentVoList && record.monitorContentVoList.length == 0)">
							<template slot="title">
								<span>暂无数据</span>
							</template>
							<span>查看结果</span>
						</a-tooltip>
						<a v-else @click="lookResult(record)">查看结果</a>
					</template>
					<template v-else>
						<a-tooltip placement="top">
							<template slot="title">
								<span>暂无数据</span>
							</template>
							<span>查看结果</span>
						</a-tooltip>
					</template>
				</span>
				<span slot="aptitudes" slot-scope="text, record">
					<a v-if="record.aptitudesFile" :href="fileBaseUrl + record.aptitudesFile" target="_blank">下载原文件</a>
					<span v-else>--</span>
				</span>

				<template slot="footer">
					<div class="tableFooter">
						<a-row>
							<a-col :span="3">
								订单总金额：
								<span class="orange">{{ data.totalAmount ? '￥' + data.totalAmount : '￥0' }}</span>
							</a-col>
							<a-col :span="3">
								优惠折扣：
								<span class="orange">{{ data.discountAmount ? '￥' + data.discountAmount : '￥0' }}</span>
							</a-col>
							<a-col :span="3">
								实际支付：
								<span class="red">{{ data.payAmount ? '￥' + data.payAmount : '￥0' }}</span>
							</a-col>
						</a-row>
					</div>
				</template>
			</a-table>

			<div v-if="data.acctPaymentVo && data.acctPaymentVo.state">
				<a-row class="titleRow">
					<span class="longString"></span>
					退款信息
				</a-row>
				<a-row class="row">
					<a-col :span="3">
						退款订单额：
						<span class="orange">{{ data.acctPaymentVo.refundAmount }}</span>
					</a-col>
					<a-col :span="3">到帐方式：{{ data.acctPaymentVo.paymentType == 1 ? '支付宝' : data.acctPaymentVo.paymentType == 2 ? '微信' : '余额' }}</a-col>
					<a-col :span="3">
						退款状态：{{ data.acctPaymentVo.state == 0 ? '待支付' : data.acctPaymentVo.state == 1 ? '已支付' : data.acctPaymentVo.state == 2 ? '部分退款' : '全部退款' }}
					</a-col>
					<a-col :span="6">到帐时间：{{ moment(data.acctPaymentVo.addTime) }}</a-col>
				</a-row>
			</div>
			<a-row class="footerRow">
				<a-button
					class="button"
					type="primary"
					@click="
						() => {
							this.$router.go(-1);
						}
					"
				>
					返回
				</a-button>
			</a-row>
		</a-row>
		<DetectionResult :detailVisible="detailVisible" :closeDetailModal="closeDetailModal" :monitorContentVoList="monitorContentVoList" :illData="illData" :resultType="resultType" />
		<Vviewer ref="viewer"></Vviewer>
		<MediaViewer :visible="mediaVisible" :mediaSrc="mediaSrc" :mediaType="mediaType" :closeMediaModal="closeMediaModal" />
		<TextViewer :textVisible="textVisible" :contentText="contentText" :closeTextModal="closeTextModal" />
	</div>
</template>

<script>
import DetectionResult from './components/DetectionResult';
import Vviewer from '../../components/Vviewer';
import MediaViewer from '../../components/MediaViewer';
import TextViewer from '../../components/TextViewer';
export default {
	components: {
		DetectionResult,
		Vviewer,
		MediaViewer,
		TextViewer,
	},
	data() {
		const _this = this;
		return {
			textVisible: false,
			fileBaseUrl: process.env.VUE_APP_RESOURCES_HOST,
			contentText: '',
			illData: {}, //子订单检测内容
			monitorContentVoList: [], //子订单检测违法内容
			industry: '',
			data: {},
			dataList: [],
			columns: [
				{
					title: '检测单号',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{ title: '检测内容', dataIndex: 'content', key: 'content', scopedSlots: { customRender: 'content' } },
				{
					title: '实付金额',
					dataIndex: 'payAmount',
					key: 'payAmount',
					customRender(text, record) {
						return text || text == 0 ? '￥' + text : '--';
					},
				},
				{
					title: '检测状态',
					dataIndex: 'state',
					key: 'state',
					customRender(text, record) {
						return text == 0 ? '待检测' : text == 1 ? '检测完成' : text == 2 ? '检测中' : text == 3 ? '检测失败' : '--';
					},
				},
				{
					title: '检测时间',
					key: 'handingTime',
					dataIndex: 'handingTime',
					customRender(text) {
						return _this.data.state == 0 ? '--' : text ? _this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '--';
					},
				},
				{ title: '操作', key: 'operation', scopedSlots: { customRender: 'operation' } },
				{
					title: '资质文件',
					key: 'aptitudes',
					dataIndex: 'aptitudes',
					scopedSlots: { customRender: 'aptitudes' },
				},
			],
			detailVisible: false,
			mediaSrc: null,
			mediaType: null,
			mediaVisible: false,
			host: '',
			resultType: '',
			tableHeight: 450,
		};
	},
	created() {
		this.host = process.env.VUE_APP_RESOURCES_HOST;
		let tradeId = this.$route.query.tradeId;
		let params = {
			tradeId: tradeId,
		};
		this.$http
			.post('/monitortrade/allTradeList', params)
			.then(res => {
				if (res.rescode == 200) {
					this.dataList = res.data.dataList[0].monitorOrdervOList;
					this.data = res.data.dataList[0];
					this.industry = res.data.dataList[0].monitorOrdervOList[0].industry;
					this.resultType = res.data.dataList[0].type;
				} else {
					this.$message.warning(res.msg);
				}
			})
			.catch(error => {
				console.log(error);
			});

		if (document.body.clientWidth <= 1366) {
			this.tableHeight = 230;
		} else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
			this.tableHeight = 330;
		} else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
			this.tableHeight = 300;
		} else if (document.body.clientWidth > 1600) {
			this.tableHeight = 400;
		}
	},
	computed: {
		coverImgUrl() {
			return data => {
				if (data.indexOf('http') != '-1') {
					return data;
				} else {
					// return 'https://resources.xbov.cn' + data;
					return this.fileBaseUrl + data;
				}
			};
		},
	},
	methods: {
		moment(data) {
			return this.$moment(data).format('YYYY-MM-DD HH:mm:ss');
		},
		closeMediaModal() {
			this.mediaVisible = false;
			this.mediaSrc = null;
			this.mediaType = null;
		},
		closeTextModal() {
			this.textVisible = false;
			// this.contentText = "";
		},
		preview(data) {
			if (data.contentType == 1) {
				this.$refs.viewer.show([
					{
						thumbnail: data.content,
						source: data.content,
					},
				]);
			} else if (data.contentType == 2) {
				this.mediaSrc = data.content;
				this.mediaVisible = true;
				this.mediaType = 2;
			} else if (data.contentType == 3) {
				this.mediaSrc = data.content;
				this.mediaVisible = true;
				this.mediaType = 3;
			} else if (data.contentType == 4) {
				this.contentText = data.content;
				this.textVisible = true;
			}
		},
		mediaLook(url, type) {
			//预览视频，音频
			this.mediaSrc = process.env.VUE_APP_RESOURCES_HOST + url;
			this.mediaVisible = true;
			this.mediaType = type;
		},
		textLook(text) {
			//预览文本
			this.contentText = text;
			this.textVisible = true;
		},
		lookResult(data) {
			this.detailVisible = true;
			this.illData = data;
			this.monitorContentVoList = data.monitorContentVoList;
		},
		closeDetailModal() {
			this.detailVisible = false;
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	font-size: 16px;
	.orderDetail {
		height: 100%;
		background: #fff;
	}
	.titleRow {
		margin-top: 20px;
		font-size: 20px;
		position: relative;
		margin-left: 20px;
		.longString {
			width: 3px;
			height: 25px;
			background-color: #2d7bf8;
			position: absolute;
			top: -3px;
			left: -10px;
		}
	}
	.footerRow {
		margin-top: 20px;
		.button {
			float: right;
			margin-right: 20px;
		}
	}
	h3 {
		font-weight: 800;
	}
	.row {
		margin-top: 10px;
		padding: 5px 30px;
	}
	.longsString {
		margin: 20px 0;
	}
	.table {
		.img {
			width: 70px;
			height: 40px;
			cursor: pointer; //鼠标变小手
		}
		.icon {
			font-size: 25px;
			cursor: pointer; //鼠标变小手
		}
		.textLook {
			cursor: pointer; //鼠标变小手
		}
		.video {
			position: relative;
			.img {
				width: 70px;
				height: 40px;
			}
			.icon {
				font-size: 25px;
				cursor: pointer; //鼠标变小手
				position: absolute;
				top: -16px;
				left: 22px;
				color: #fff;
			}
		}
	}
	.orange {
		color: #ff9e02;
	}
	.blue {
		color: #40a9ff;
	}
	.red {
		color: red;
	}
}
</style>
