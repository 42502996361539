<template>
    <a-modal class="illegalAdSubModal" :title="false" :footer="false" :visible="detailVisible" :width="1000" @cancel="handleCancel">
        <div v-if="monitorContentVoList && monitorContentVoList.length > 0">
            <a-row class="row">
                <a-col :span="12" class="header">检测类型</a-col>
                <a-col :span="12" class="header">检测内容</a-col>
            </a-row>
            <a-row class="row">
                <a-col :span="12" class="son">{{ illData.contentType == 1 ? "图片" : illData.contentType == 2 ? "视频" : illData.contentType == 3 ? "音频" : illData.contentType == 4 ? "文本" : "--" }}</a-col>
                <a-col :span="12" class="son" @click="preview(illData)">
                    <img v-if="illData.contentType == 1" :src="illData.content" class="img" />
                    <span class="video" v-else-if="illData.contentType == 2 || illData.contentType == 3">
                        <img :src="illData.coverImgUrl" class="img" />
                        <a-icon type="play-circle" class="icon" />
                    </span>
                    <span v-else-if="illData.contentType == 4">{{ illData.content && illData.content.length > 8 ? illData.content.substring(0, 8) + "..." : illData.content ? illData.content : "--" }}</span>
                </a-col>
            </a-row>
            <a-collapse accordion v-model="activeKey" v-for="monitorContentItem in monitorContentVoList" :key="monitorContentItem.flowId" class="list">
                <a-collapse-panel :key="monitorContentItem.flowId">
                    <a-row slot="header">
                        <span v-if="illData.contentType == 2">
                            进度：<span class="illegal blue">{{ monitorContentItem.eachSeek }}秒 <a @click="lookPic(monitorContentItem.previewSrc, 'img')">查看</a></span>
                        </span>
                        <span v-if="illData.contentType == 3">
                            进度：<span class="illegal blue">{{ monitorContentItem.audioStartTime + "~" + monitorContentItem.audioEndTime }} <a @click="lookPic(monitorContentItem.illegalContent, 'audio')">查看</a></span>
                        </span>
                        违规内容 ：{{ monitorContentItem.illegalContent || "--" }}
                    </a-row>
                    <a-collapse accordion v-for="(keywordItem, keywordIndex) in monitorContentItem.monitorKeywordVos" :key="keywordIndex">
                        <a-collapse-panel :key="keywordIndex" v-if="illData.contentType == 1 || illData.contentType == 4">
                            <a-row slot="header">
                                <span class="illegal">关键词 ：{{ keywordItem.keyWordName }}</span
                                >级别：<a-tag :color="keywordItem.keywordLevel == 1 ? '#ccc' : keywordItem.keywordLevel == 2 ? '#fa8c16' : keywordItem.keywordLevel == 3 ? '#fadb14' : keywordItem.keywordLevel == 4 ? '#f5222d' : ''">{{
                                    keywordItem.keywordLevel == 1 ? "普通" : keywordItem.keywordLevel == 2 ? "重点" : keywordItem.keywordLevel == 3 ? "紧急" : keywordItem.keywordLevel == 4 ? "特殊" : ""
                                }}</a-tag>
                            </a-row>
                            <a-row> 检测内容：{{ monitorContentItem.content || "--" }} </a-row>
                            <a-row class="row" v-if="resultType != 1">
                                <a-col :span="2">法条：</a-col>
                                <a-col :span="22">
                                    <a-table :columns="illegalColumns" :data-source="keywordItem.monitorLawVo" size="small">
                                        <span slot="content" slot-scope="text" class="blueHand" @click="contentLook(text)">
                                            {{ text && text.length > 10 ? text.substring(0, 10) + "..." : text }}
                                        </span>
                                    </a-table>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                        <a-collapse-panel :key="keywordIndex" v-if="illData.contentType == 2">
                            <a-row slot="header">
                                <span class="illegal">
                                    违规帧数：第
                                    <span style="color: #f5222d">{{ monitorContentItem.eachSeek }}</span>
                                    秒 关键词 ：{{ keywordItem.keyword }}
                                </span>
                                级别：<a-tag :color="keywordItem.keywordLevel == 1 ? '#ccc' : keywordItem.keywordLevel == 2 ? '#fa8c16' : keywordItem.keywordLevel == 3 ? '#fadb14' : keywordItem.keywordLevel == 4 ? '#f5222d' : ''">{{
                                    keywordItem.keywordLevel == 1 ? "普通" : keywordItem.keywordLevel == 2 ? "重点" : keywordItem.keywordLevel == 3 ? "紧急" : keywordItem.keywordLevel == 4 ? "特殊" : ""
                                }}</a-tag>
                            </a-row>
                            <a-row> 检测内容：{{ monitorContentItem.content || "--" }} </a-row>
                            <a-row class="row" v-if="resultType != 1">
                                <a-col :span="2">法条：</a-col>
                                <a-col :span="22">
                                    <a-table :columns="illegalColumns" :data-source="keywordItem.monitorLawVo" size="small">
                                        <span slot="content" slot-scope="text" class="blueHand" @click="contentLook(text)">
                                            {{ text && text.length > 10 ? text.substring(0, 10) + "..." : text }}
                                        </span>
                                    </a-table>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                    </a-collapse>
                    <a-collapse-panel :key="keywordIndex" v-if="illData.contentType == 3">
                        <a-row slot="header">
                            <span class="illegal">
                                违规帧数区间：第
                                <span style="color: #f5222d">{{ monitorContentItem.audioStartTime }}</span>
                                秒 至
                                <span style="color: #f5222d">{{ monitorContentItem.audioEndTime }}秒</span>
                                关键词 ：{{ keywordItem.keyword }}
                            </span>
                            级别：<a-tag :color="keywordItem.keywordLevel == 1 ? '#ccc' : keywordItem.keywordLevel == 2 ? '#fa8c16' : keywordItem.keywordLevel == 3 ? '#fadb14' : keywordItem.keywordLevel == 4 ? '#f5222d' : ''">{{
                                keywordItem.keywordLevel == 1 ? "普通" : keywordItem.keywordLevel == 2 ? "重点" : keywordItem.keywordLevel == 3 ? "紧急" : keywordItem.keywordLevel == 4 ? "特殊" : ""
                            }}</a-tag>
                        </a-row>
                        <a-row> 检测内容：{{ monitorContentItem.content || "--" }} </a-row>
                        <a-row class="row" v-if="resultType != 1">
                            <a-col :span="2">法条：</a-col>
                            <a-col :span="22">
                                <a-table :columns="illegalColumns" :data-source="keywordItem.monitorLawVo" size="small">
                                    <span slot="content" slot-scope="text" class="blueHand" @click="contentLook(text)">
                                        {{ text && text.length > 10 ? text.substring(0, 10) + "..." : text }}
                                    </span>
                                </a-table>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>
                </a-collapse-panel>
            </a-collapse>
            <a-row class="row" v-if="illData.isCompliance == 2">
                <a-col :span="3" class="remark">说明文档：</a-col>
                <a-col :span="21">
                    <template v-if="illData.poList && illData.poList.length != 0">
                        <a class="fileItem" v-for="fileItem in illData.poList" :key="fileItem.flowId" target="_blank" :href="resourcesHost + fileItem.url">{{ fileItem.url.split("/temp/")[1] }}</a>
                    </template>
                    <span v-else>无</span>
                </a-col>
            </a-row>
            <a-row class="row">
                <a-col :span="3" class="remark">审读建议：</a-col>
                <a-col :span="21">
                    {{ illData.remark || "--" }}
                </a-col>
            </a-row>
        </div>
        <div v-if="!monitorContentVoList">
            暂无数据
        </div>

        <Vviewer ref="viewer"></Vviewer>
        <MediaViewer :visible="mediaVisible" :mediaSrc="mediaSrc" :mediaType="mediaType" :closeMediaModal="closeMediaModal" />
        <TextViewer :textVisible="textVisible" :contentText="contentText" :closeTextModal="closeTextModal" />
    </a-modal>
</template>

<script>
import Vviewer from "../../../components/Vviewer";
import MediaViewer from "../../../components/MediaViewer";
import TextViewer from "../../../components/TextViewer";

export default {
    props: ["detailVisible", "closeDetailModal", "monitorContentVoList", "illData", "resultType"],
    components: {
        Vviewer,
        MediaViewer,
        TextViewer,
    },
    data() {
        return {
            // text: "111111111111",
            activeKey: ["1"],
            illegalColumns: [
                {
                    title: "法律名称",
                    dataIndex: "lawName",
                    key: "lawName",
                    width: 280,
                },
                {
                    title: "内容",
                    dataIndex: "content",
                    key: "content",
                    // customRender(text, record) {
                    //     return text && text.length > 10 ? text.substring(0, 10) + "..." : text;
                    // },
                    scopedSlots: { customRender: "content" },
                },
            ],
            data: [],
            textVisible: false,
            contentText: "",
            mediaSrc: null,
            mediaType: null,
            mediaVisible: false,
            resourcesHost: "",
        };
    },
    created() {
        let userInfo = this.$store.getters.userInfo;
        this.phone = userInfo.phone;
        this.resourcesHost = process.env.VUE_APP_RESOURCES_HOST;
    },
    watch: {
        b: {
            //深度监听，可监听到对象、数组的变化
            handler(val, oldVal) {
                console.log("b.c: " + val.c, oldVal.c);
            },
            deep: true, //true 深度监听
        },
    },
    methods: {
        handleCancel() {
            this.closeDetailModal();
        },
        lookPic(content, type) {
            event.stopPropagation(); //阻止冒泡
            console.log("111111", content, type);
            if (type == "img") {
                this.$refs.viewer.show([
                    {
                        thumbnail: content,
                        source: content,
                    },
                ]);
            } else if (type == "audio") {
                this.contentText = content;
                this.textVisible = true;
            }
        },
        preview(data) {
            if (data.contentType == 1) {
                this.$refs.viewer.show([
                    {
                        thumbnail: data.content,
                        source: data.content,
                    },
                ]);
            } else if (data.contentType == 2) {
                this.mediaSrc = data.content;
                this.mediaVisible = true;
                this.mediaType = 2;
            } else if (data.contentType == 3) {
                this.mediaSrc = data.content;
                this.mediaVisible = true;
                this.mediaType = 3;
            } else if (data.contentType == 4) {
                this.contentText = data.content;
                this.textVisible = true;
            }
        },
        contentLook(text) {
            this.contentText = text;
            this.textVisible = true;
        },
        closeMediaModal() {
            this.mediaVisible = false;
            this.mediaSrc = null;
            this.mediaType = null;
        },
        closeTextModal() {
            this.textVisible = false;
        },
    },
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
    /deep/ .userPhoneSmsInput {
        width: 78%;
    }
    .row {
        margin-top: 10px;
        font-size: 16px;
        .fileItem {
            display: block;
            margin-bottom: 8px;
        }
        .fileItem:last-child {
            margin-bottom: 0;
        }
        .header {
            padding-top: 3px;
            padding-left: 20px;
            font-size: 20px;
            font-weight: 700;
        }
        .son {
            padding-top: 3px;
            padding-left: 20px;
        }
        .img {
            width: 100px;
            height: 100px;
        }
        .video {
            position: relative;
            .img {
                width: 100px;
                height: 50px;
            }
            .icon {
                font-size: 25px;
                cursor: pointer; //鼠标变小手
                position: absolute;
                top: -20px;
                left: 40px;
                color: #fff;
            }
        }
    }
    .footerRow {
        margin-top: 10px;
        margin-left: 20px;
        font-size: 16px;
    }
    .list {
        .illegal {
            margin-right: 10px;
        }
        .blue {
            color: #40a9ff;
        }
    }
    .blueHand {
        color: #40a9ff;
        cursor: pointer;
    }
}
</style>
